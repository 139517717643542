import React from "react";
import "./Events.css";
import { BsArrowRightShort } from 'react-icons/bs';
import { Link } from "react-router-dom";

function Events() {
    return (
        <>

            <section className=" bgevent titlebar columns abs">
                <div className="row1 ">
                    <div className="text-center mt-4">
                        <h1 id="page-title" className="title fnt">Events</h1>

                    </div>
                </div>
            </section>
            <div className="">


                <div className="text-center">
                    <div className="bgevent">
                        <div className="container text-center">
                            <div className="mt-0">
                                <img src="./images/event/eventimpro2.png" alt="car" style={{ width: "100%", maxWidth: "813px" }} />
                            </div>
                        </div>
                    </div>
                    <div className="container mt-5">
                        <div class="row">
                            <div class="col-lg-4 col-md-12 mb-4 mb-lg-0"
                            >
                                <img
                                    src="./images/eventNew/event1.jpg"
                                    className="w-100 shadow-1-strong rounded mb-4"
                                    alt="Boat on Calm Water"
                                    style={{
                                        height: '21rem',
                                        objectFit: 'cover'
                                    }}
                                />

                                <img
                                    src="./images/eventNew/event2.jpg"
                                    className="w-100 shadow-1-strong rounded mb-4"
                                    alt="Wintry Mountain Landscape"
                                    style={{
                                        height: '21rem',
                                        objectFit: 'cover'
                                    }}
                                />
                            </div>

                            <div class="col-lg-4 mb-4 mt-5 mb-lg-0" style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <img
                                    src="./images/eventNew/event4.jpg"
                                    className="w-100 shadow-1-strong rounded mb-4"
                                    alt="Mountains in the Clouds"
                                />
                            </div>

                            <div class="col-lg-4 mb-4 mb-lg-0">
                                <img
                                    src="./images/eventNew/event3.jpg"
                                    className="w-100 shadow-1-strong rounded mb-4"
                                    alt="Waves at Sea"
                                    style={{
                                        height: '21rem',
                                        objectFit: 'cover'
                                    }}
                                />

                                <img
                                    src="./images/eventNew/event5.jpg"
                                    className="w-100 shadow-1-strong rounded mb-4"
                                    alt="Yosemite National Park"
                                />
                            </div>
                        </div>
                    </div>
                    <div className=" bgevent" style={{
                        marginTop: 85
                    }}>
                        <div className="container text-center">
                            <div className="mt-5">
                                <img src="./images/event/eventimpro.png" alt="car" style={{ width: "100%", maxWidth: "813px" }} />
                            </div>
                            {/* <div className=" mt-5 container evnt" >
                                <h2>Booth No. 4.1L161</h2>
                                <p className="p-5 ">You're invited to explore our booth, Booth No. 4.1L161, at Fi Europe 2023 in Frankfurt, Germany, taking place
                                    from the 28th to the 30th of November 2023. Join us for an exciting and informative experience at the event.
                                    Looking forward to meeting you there!</p>
                            </div> */}
                        </div>
                    </div>




                    {/* <div class="row gy-4 isotope-container" data-aos="fade-up" data-aos-delay="200">

                        <div class="col-lg-4 col-md-6 portfolio-item isotope-item filter-app">
                            

                            <img src="./images/event/event1.jpg"  class="img-fluid" alt="car" style={{ width: "100%", maxWidth: "813px" }} />
                            <div class="portfolio-info">
                                <h4>App 1</h4>
                                <p>Lorem ipsum, dolor sit</p>
                                <a href="assets/img/masonry-portfolio/masonry-portfolio-1.jpg" title="App 1" data-gallery="portfolio-gallery-app" class="glightbox preview-link"><i class="bi bi-zoom-in"></i></a>
                                <a href="portfolio-details.html" title="More Details" class="details-link"><i class="bi bi-link-45deg"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 portfolio-item isotope-item filter-product">

                            <img src="./images/event/event2.jpg"  class="img-fluid" alt="car" style={{ width: "100%", maxWidth: "813px" }} />
                            <div class="portfolio-info">
                                <h4>Product 1</h4>
                                <p>Lorem ipsum, dolor sit</p>
                                <a href="assets/img/masonry-portfolio/masonry-portfolio-2.jpg" title="Product 1" data-gallery="portfolio-gallery-product" class="glightbox preview-link"><i class="bi bi-zoom-in"></i></a>
                                <a href="portfolio-details.html" title="More Details" class="details-link"><i class="bi bi-link-45deg"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 portfolio-item isotope-item filter-branding">

                            <img src="./images/event/event3.jpg"  class="img-fluid" alt="car" style={{ width: "100%", maxWidth: "813px" }} />
                            <div class="portfolio-info">
                                <h4>Branding 1</h4>
                                <p>Lorem ipsum, dolor sit</p>
                                <a href="assets/img/masonry-portfolio/masonry-portfolio-3.jpg" title="Branding 1" data-gallery="portfolio-gallery-branding" class="glightbox preview-link"><i class="bi bi-zoom-in"></i></a>
                                <a href="portfolio-details.html" title="More Details" class="details-link"><i class="bi bi-link-45deg"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 portfolio-item isotope-item filter-app">

                            <img src="./images/event/event4.jpeg" class="img-fluid"  alt="car" style={{ width: "100%", maxWidth: "813px" }} />
                            <div class="portfolio-info">
                                <h4>App 2</h4>
                                <p>Lorem ipsum, dolor sit</p>
                                <a href="assets/img/masonry-portfolio/masonry-portfolio-4.jpg" title="App 2" data-gallery="portfolio-gallery-app" class="glightbox preview-link"><i class="bi bi-zoom-in"></i></a>
                                <a href="portfolio-details.html" title="More Details" class="details-link"><i class="bi bi-link-45deg"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 portfolio-item isotope-item filter-product">

                            <img src="./images/event/event5.jpg"  class="img-fluid" alt="car" style={{ width: "100%", maxWidth: "813px" }} />
                            <div class="portfolio-info">
                                <h4>Product 2</h4>
                                <p>Lorem ipsum, dolor sit</p>
                                <a href="assets/img/masonry-portfolio/masonry-portfolio-5.jpg" title="Product 2" data-gallery="portfolio-gallery-product" class="glightbox preview-link"><i class="bi bi-zoom-in"></i></a>
                                <a href="portfolio-details.html" title="More Details" class="details-link"><i class="bi bi-link-45deg"></i></a>
                            </div>
                        </div>
                    </div> */}



                    {/* <div class="row">
                        <div class="col-lg-4 col-md-12 mb-4 mb-lg-0">
                            <img
                                src="./images/event/event1.jpg"
                                class="w-100 shadow-1-strong rounded mb-4"
                                alt="Boat on Calm Water"
                            />

                            <img
                                src="./images/event/event1.jpg"
                                class="w-100 shadow-1-strong rounded mb-4"
                                alt="Wintry Mountain Landscape"
                            />
                        </div>

                        <div class="col-lg-4 mb-4 mb-lg-0">
                            <img
                                src="./images/event/event1.jpg"
                                class="w-100 shadow-1-strong rounded mb-4"
                                alt="Mountains in the Clouds"
                            />

                            <img
                                src="./images/event/event1.jpg"
                                class="w-100 shadow-1-strong rounded mb-4"
                                alt="Boat on Calm Water"
                            />
                        </div>

                        <div class="col-lg-4 mb-4 mb-lg-0">
                            <img
                                src="./images/event/event1.jpg"
                                class="w-100 shadow-1-strong rounded mb-4"
                                alt="Waves at Sea"
                            />

                            <img
                                src="./images/event/event1.jpg"
                                class="w-100 shadow-1-strong rounded mb-4"
                                alt="Yosemite National Park"
                            />
                        </div>
                    </div> */}



                    <div className="container mt-5">
                        <div class="row">
                            <div class="col-lg-4 col-md-12 mb-4 mb-lg-0">
                                <img
                                    src="./images/event/event4.jpeg"
                                    class="w-100 shadow-1-strong rounded mb-4"
                                    alt="Boat on Calm Water"
                                />

                                <img
                                    src="./images/event/event11.png"
                                    class="w-100 shadow-1-strong rounded mb-4"
                                    alt="Wintry Mountain Landscape"
                                />
                            </div>

                            <div class="col-lg-4 mb-4 mt-5 mb-lg-0">
                                <img
                                    src="./images/event/event3.jpg"
                                    class="w-100 shadow-1-strong rounded mb-4"
                                    alt="Mountains in the Clouds"
                                />


                            </div>

                            <div class="col-lg-4 mb-4 mb-lg-0">
                                <img
                                    src="./images/event/event2.png"
                                    class="w-100 shadow-1-strong rounded mb-4"
                                    alt="Waves at Sea"
                                />

                                <img
                                    src="./images/event/event5.png"
                                    class="w-100 shadow-1-strong rounded mb-4"
                                    alt="Yosemite National Park"
                                />
                            </div>
                        </div>
                    </div>



                </div>
            </div >
        </>
    );
}

export default Events;